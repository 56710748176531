<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
        <div class="total-counts">
            <div class="total-count total-careers  col-lg-5 mt-3"
                routerLink="/analytics-dashboard/registered-experiencers">
                <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/Devices.svg" alt="" height="60" width="60">
                <div>
                    
                    <h1 id="client">{{registeredExp}}</h1>


                    
                    <p>Total Number of Registered Experiencers</p>

                </div>
            </div>

            <div class="total-count total-careers  col-lg-5 mt-3" routerLink="/analytics-dashboard/registered-locals">
                <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/Downloads.svg" alt="" height="60" width="60">
                <div>
                   
                    <h1 id="client1">{{registeredLocal}}</h1>


                    <p>Total Number of Registered Locals</p>


                </div>
            </div>



            <div class="total-count total-gs  col-lg-5 mt-3" routerLink="/manage/transactionhistory">
                <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/Active age group.svg" alt="" height="60" width="60">
                <div>
                   
                    <h1 id="client1">{{totalBookings}}</h1>

                    <p>Total Number of Bookings</p>


                </div>
            </div>

            <div class="total-count total-resources  col-lg-5 mt-3">
                <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/View Feedback.svg" alt="" height="60" width="60">
                <div>
                 
                    <h1 id="client12">${{totalRevenue| number}}</h1>

                    <p>Total Revenue Generated</p>


                </div>
            </div>

        </div>
    </div>
</div>