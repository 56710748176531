import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Chart } from 'node_modules/chart.js/dist/chart.js';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData: any;
  graphData: any;
  months: number = 6;
  currentUser: any;
  registeredExp: any;

  registeredLocal: any;
  totalBookings: any;
  totalRevenue:any;
  formattedNumber: any


  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.getCount()
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  getCount() {
    this.dashboardService.getDashboardCount({ "reportType": "homePage" }).subscribe((res: any) => {
      if (res.status == 200) {
        this.registeredExp = res.output.registeredExp
        this.registeredLocal = res.output.registeredLocal
        this.totalBookings = res.output.totalBookings
        this.totalRevenue=res.output.totalRevenue
       
     
      }
    })
  }

  getFormattedNumber(value: any) {
 this.formattedNumber = value.toLocaleString('en-US');
        document.getElementById("client12").innerHTML = this.formattedNumber;
        const numberFormat = this.formattedNumber
        console.log(this.formattedNumber);
        return numberFormat
  }

 getCounter() {
 
  const formattedNumber = this.totalRevenue.toLocaleString('en-US');
  document.getElementById("client1").innerHTML = formattedNumber;
  console.log(formattedNumber);
}



  animateCounter(obj, initVal, lastVal, duration) {
    let startTime = null;

    
    let currentTime = Date.now();

    
    const step = (currentTime) => {

     
      if (!startTime) {
        startTime = currentTime;
      }

     
      const progress = Math.min((currentTime - startTime) / duration, 20);

      
      obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);

      
      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        window.cancelAnimationFrame(window.requestAnimationFrame(step));
      }
    };
   
    window.requestAnimationFrame(step);
  }

}
