

export const environment = {
  production: false,
  

  //API_URL: "https://api-stg-wareeg.approd.ca/v1/",  


    API_URL: "https://api-wareeg.approd.ca/v1/",
  
 //SERVER: "stg",
 SERVER: "dev",








  


  
};
