

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class TransactionHistoryService {

    url: any = environment.API_URL;
    listData: any;


    constructor(
        private http: HttpClient
    ) {

    }

    setData(data){
        this.listData = data
    }

    getData(){
        return this.listData
    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getTransactionHistoryList(data:any){
        return this.http.post(this.url + "pm/adminTransHistory", data);
    }

    approveCaregiver(data:any){
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    getCaregiverById(data:any){
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    setStatus(data:any){
        return this.http.post(this.url + "user/change-status", data);
    }

}

