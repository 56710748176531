import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _  from 'lodash';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable()
export class ManageService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
    console.log(json ,"xl")
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        console.log('worksheet',worksheet);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        console.log(workbook,"wb")
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        console.log(workbook,"excelbuffer")

        
        this.saveAsExcelFile(excelBuffer, excelFileName);
      }
    
      private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'user/upload-image', data);
    }


    createService(data:any){
        return this.http.post(this.url + 'service/create', data);
    }

  

    deleteService(data:any){
        return this.http.post(this.url + 'service/delete', data);
    }

    editService(data:any){
        return this.http.post(this.url + 'service/edit', data);
    }

    getearlysubscribersList(data:any){
        return this.http.post(this.url + 'earlyAccess/listFrontend', data);
    }
}

