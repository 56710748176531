

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageClientsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getContactList(data:any){
        return this.http.post(this.url + 'contact/list', data)
    } 

    

}