

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageLocalService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getCaregiverList(data:any){
        return this.http.post(this.url + "user/list-caregiver", data);
    }

    approveCaregiver(data:any){
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    getCaregiverById(data:any){
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    setStatus(data:any){
        return this.http.post(this.url + "user/change-status", data);
    }

    getManageLocalList(data:any){
        return this.http.post(this.url + "user/admin/get", data)
    }

    getUserStatus(data:any){
        return this.http.post(this.url + "user/admin/active", data);
    }

    getManageLocalById(data:any){
        return this.http.post(this.url + 'user/get', data)
    }

}