<div class="sidebar">
  <div class="logo"><img src="../../../assets/Wareeg images/wareeg image.png" alt="logo"></div>

  <div class="header-links">

    
    <a routerLink="/analytics-dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt=""  height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
    </span>Reporting</a>


    <a routerLink="/manage/interest" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/manage Interests.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/manage Interests.svg" alt="" height="24" width="24">
    </span>Manage Interest</a>

    <a routerLink="/manage/local" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Manage Local.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Manage Local.svg" alt="" height="24" width="24">
    </span>Manage Local</a>

    <a routerLink="/manage/broadcastnotification" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Brodcast Notification.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Brodcast Notification.svg" alt="" height="24" width="24">
    </span>Broadcast Notifications</a>

    <a routerLink="/manage/transactionhistory" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Transaction History.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Transaction History.svg" alt="" height="24" width="24">
    </span>Transaction History</a>

    <!-- <a routerLink="/manage/earlysubscribers" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Early Subscribers.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Early Subscribers.svg" alt="" height="24" width="24">
    </span>Early Subscribers</a> -->

    <a routerLink="/manage/clients" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Early Subscribers.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Early Subscribers.svg" alt="" height="24" width="24">
    </span>Contact us</a>

    <!-- <a routerLink="/manage/tutorial" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Early Subscribers.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Early Subscribers.svg" alt="" height="24" width="24">
    </span>Extra Taxes</a> -->

   

    


    
  </div>
</div>